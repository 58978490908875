<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5">
        <b-col cols="12">
          <StepFormRegister :currentStep="4" :titleListSteps="this.titleListSteps" />
        </b-col>
        <b-col xl="12" lg="12" sm="12" md="12" class="text-center mt-1 mt-md-4">
          <h4 class="" v-if="school && !school.is_approved"> {{ $t('Status') }} : {{ $t('Waiting for approval') }}</h4>
          <h4 class="" v-if="school && school.is_approved"> {{ $t('Status') }} : {{ $t('Waiting for payment') }}</h4>
          <b-button v-if="school && school.is_approved && school.status == 1" pill class="btn-primary-dark mt-4 font-weight-bold px-5" @click="$router.push({ name: 'billing' })">{{ $t('Billing') }}</b-button>
          <b-button v-if="school && school.status == 2" pill class="btn-primary-dark mt-4 font-weight-bold px-5" @click="$router.push({ name: 'schoolProfile' })">{{ $t('View profile') }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'

export default {
  name: 'SchoolRegisterPayment',
  title: ' | School register success',
  components: {
    StepFormRegister
  },
  data () {
    return {
      school: null,
      currentStep: 4,
      titleListSteps: [this.$t('Start'), this.$t('School info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Payment'), this.$t('Finished')]
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.getSchool()
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getSchool () {
      Api.listSchool({ user: this.userProfile.id }).then(response => {
        if (response.data.results) {
          this.school = response.data.results[0]
          if (this.school.status === 2) {
            this.$router.push({ name: 'schoolRegisterSuccess' })
          }
        }
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style >

</style>
